<template>
  <div class="box">
    <van-cell-group inset>
      <template slot="title">
        <span class="part_title">基础信息</span>
      </template>
      <van-cell title="所属组织" :value="boxInfo.orgName" />
      <van-cell title="安装批次" :value="boxInfo.version" />
      <van-cell title="安装地址" :value="boxInfo.boxAddress" />
      <van-cell title="箱编号" :value="boxInfo.number" />
    </van-cell-group>
    <van-cell-group inset>
      <template slot="title">
        <span class="part_title">运维信息</span>
      </template>
      <van-cell title="光负责人" :value="boxInfo.netContactName ? boxInfo.netContactName : '--'" />
      <van-cell title="联系方式" :value="boxInfo.netContactPhone ? boxInfo.netContactPhone : '--'" />
      <van-cell title="电负责人" :value="boxInfo.powerContactName ? boxInfo.powerContactName : '--'" />
      <van-cell title="联系方式" :value="boxInfo.powerContactPhone ? boxInfo.powerContactPhone : '--'" />
    </van-cell-group>
    <van-cell-group inset>
      <template slot="title">
        <span class="part_title">电气连接图</span>
      </template>
      <van-cell>
        <div v-if="boxInfo.powerEngineeringImage.length">
        <van-image
          v-for="(item, index) of boxInfo.powerEngineeringImage"
          :key="index"
          width="100%"
          fit="contain"
          :src="item"
          @click="onPreview(0, index)"
        />
        </div>
        <van-image
          v-else
          width="160px"
          fit="contain"
          class="nodata"
          :src="require('../../assets/images/nodata.png')"
        />
      </van-cell>
    </van-cell-group>
    <van-cell-group inset>
      <template slot="title">
        <span class="part_title">光电施工图</span>
      </template>
      <van-cell>
        <div v-if="boxInfo.netEngineeringImage.length">
          <van-image
            v-for="(item, index) of boxInfo.netEngineeringImage"
            :key="index"
            width="100%"
            fit="contain"
            :src="item"
            @click="onPreview(1, index)"
          />
        </div>
        <van-image
          v-else
          width="160px"
          fit="contain"
          class="nodata"
          :src="require('../../assets/images/nodata.png')"
        />
      </van-cell>
    </van-cell-group>
    <!-- 图片预览 -->
    <van-image-preview v-model="show" :images="images" :startPosition="startPosition" closeable />
  </div>
</template>

<script>
  import { getBoxManagerByNumber } from '@/api/box'
  export default {
    data() {
      return {
        show: false,
        images: [],
        boxInfo: {
          boxAddress: '',
          netContactName: '',
          netContactPhone: '',
          netEngineeringImage: [],
          number: '',
          orgName: '',
          powerContactName: '',
          powerContactPhone: '',
          powerEngineeringImage: [],
          version: ''
        },
        startPosition: 0
      }
    },
    created() {
      this.getBoxManagerByNumber()
    },
    methods: {
      async getBoxManagerByNumber() {
        const { versionId, number } = this.$route.query
        const res = await getBoxManagerByNumber(versionId, number)
        this.boxInfo = res.data
      },
      onPreview(type, index) {
        const { netEngineeringImage, powerEngineeringImage } = this.boxInfo
        if (type) {
          this.images = netEngineeringImage
        } else {
          this.images = powerEngineeringImage
        }
        this.startPosition = index
        this.show = true
      }
    }
  }
</script>

<style lang="less" scoped>
.nodata {
  display: block;
  margin: 20px auto;
}
.box {
  padding: 16px 0 24px;
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  .part_title {
    font-size: 16px;
    font-weight: 500;
    color: #323233;
    position: relative;
    &::before {
      width: 4px;
      height: 10px;
      border-radius: 2px;
      position: absolute;
      top: 4px;
      left: -10px;
      content: '';
      background: #409EFF;
    }
  }
}
::v-deep [class*=van-hairline]::after {
  border: none;
}
</style>